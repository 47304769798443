import { LANDINGS } from '@routes/path'
import { useLocation, useNavigate } from 'react-router-dom'

import { WizardFormValues } from '../types'
import { optionsWithAccessToThirdStep } from './constants'

const useSteps = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const stepMap: { [key: string]: number } = {
    [LANDINGS.LANDINGS_CREATE_WIZARD_FIRST]: 1,
    [LANDINGS.LANDINGS_CREATE_WIZARD_SECOND]: 2,
    [LANDINGS.LANDINGS_CREATE_WIZARD_THIRD]: 3,
    [LANDINGS.LANDINGS_CREATE_WIZARD_FOURTH]: 4,
    [LANDINGS.LANDINGS_CREATE_WIZARD_FIFTH]: 5,
    [LANDINGS.LANDINGS_CREATE_WIZARD_SIXTH]: 6,
    [LANDINGS.LANDINGS_WIZARD_GENERATION]: 7
  }

  const stepNumber = stepMap[location.pathname]

  const findNextStepUrl = (formValues: WizardFormValues) => {
    let nextStepNumber = stepNumber + 1
    const { topGoal } = formValues

    if (
      stepNumber === 2 &&
      !optionsWithAccessToThirdStep.includes(topGoal as string)
    ) {
      nextStepNumber++
    }

    return Object.keys(stepMap).find(key => stepMap[key] === nextStepNumber)
  }

  const nextStep = (formValues: WizardFormValues) => {
    const nextStepUrl = findNextStepUrl(formValues)

    if (nextStepUrl) {
      navigate(nextStepUrl)
    }
  }

  const stepsLimit = Object.keys(stepMap).length

  return { stepNumber, nextStep, stepsLimit }
}

export default useSteps
