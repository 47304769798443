import { useWizardContext } from '@pages/Landings/routes/Landings/CreateLandingWizard/contexts'
import { Button, Heading, Paragraph, Search, Spacer, Spreader } from '@ui-kit'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

import { StyleBox } from './components/StyleBox'
import styles from './Sixth.module.scss'

export const SixthStep: React.FC = () => {
  const { t } = useTranslation()
  const { validators } = useWizardContext()
  const { isSixthStepValid } = validators
  const [searchPhrase, setSearchPhrase] = useState('')

  return (
    <div>
      <Heading level={1} weight={600}>
        {t('landings.create.wizard.landing.modal.find.a.topic')}
      </Heading>
      <Spacer space={20} />
      <Paragraph size={14} color='neutral-6'>
        {t('landings.create.wizard.landing.modal.enter.a.keyword')}
      </Paragraph>
      <Spacer space={20} />
      <Search
        setSearchPhrase={setSearchPhrase}
        searchPhrase={searchPhrase}
        liveChanges
        autoFocus
        i18n={{ placeholder: t('landings.create.wizard.landing.modal.search') }}
      />
      <Spacer space={20} />

      {
        // TODO fetch real topics for styleboxes from backend
      }
      {searchPhrase === 'Health' && (
        <div className={styles['fourth-step__grid']}>
          <StyleBox topic='Medical' />
          <StyleBox topic='Nutrition' />
          <StyleBox topic='Sport' />
          <StyleBox topic='Food' />
          <StyleBox topic='Drinks' />
          <StyleBox topic='Therapy' />
        </div>
      )}
      {searchPhrase === 'Sales' && (
        <div className={styles['fourth-step__grid']}>
          <StyleBox topic='Marketing' />
          <StyleBox topic='Ecommerce' />
          <StyleBox topic='Retail' />
          <StyleBox topic='Sales 1' />
          <StyleBox topic='Sales 2' />
          <StyleBox topic='Sales 3' />
        </div>
      )}
      <Spacer space={30} />
      <Row justifyContent='end'>
        <Button variant='text-neutral'>
          {t('landings.create.wizard.landing.modal.skip.this.step')}
        </Button>

        <Spreader spread={10} />

        <Button
          icon='icon-arrow-right'
          disabled={!isSixthStepValid}
          type='submit'
        >
          {t('landings.create.wizard.landing.modal.finish')}
        </Button>
      </Row>
    </div>
  )
}
