import { ShortLogo } from '@components/Logo'
import { useHistoryBack } from '@hooks/useHistoryBack'
import SecondColumnContent from '@pages/Landings/routes/Landings/CreateLandingWizard/components/SecondColumnContent'
import useSteps from '@pages/Landings/routes/Landings/CreateLandingWizard/helpers/useSteps'
import { LANDINGS } from '@routes/path'
import { Button, Close, ProgressBar, Spreader } from '@ui-kit'
import { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { Row } from 'simple-flexbox'

import { useWizardContext } from '../contexts'
import styles from './WizardSurveyLayout.module.scss'

export const WizardSurveyLayout = ({ children }: { children: ReactNode }) => {
  const back = useHistoryBack(LANDINGS.LANDINGS_CREATE)
  const navigate = useNavigate()
  const { stepNumber, stepsLimit } = useSteps()
  const { handleSubmit } = useWizardContext()
  const close = () => navigate(LANDINGS.LANDINGS)

  return (
    <form onSubmit={handleSubmit}>
      <Row className={styles['wizard-survey-layout']} justifyContent='center'>
        <Row
          className={styles['wizard-survey-layout__header']}
          alignItems='center'
          justifyContent='space-between'
        >
          <Row alignItems='center'>
            <Button
              icon='icon-arrow-left'
              variant='text-neutral'
              onClick={back}
            />

            <Spreader spread={20} />

            <ShortLogo />
          </Row>

          <Row alignItems='center'>
            <div className={styles['wizard-survey-layout__progress-bar']}>
              <ProgressBar
                variant='brand'
                quantity={stepNumber}
                limit={stepsLimit}
                size={12}
              />
            </div>
            <Spreader spread={40} />

            <Close onClick={close} />
          </Row>
        </Row>
        <Row className={styles['wizard-survey-layout__content']}>
          <Row
            className={styles['wizard-survey-layout__first-column']}
            alignItems='center'
          >
            {children}
          </Row>
          <Row className={styles['wizard-survey-layout__second-column']}>
            <Row
              className={styles['wizard-survey-layout__second-column-content']}
              alignItems='center'
            >
              <SecondColumnContent />
            </Row>
          </Row>
        </Row>
      </Row>
    </form>
  )
}
